import React from "react";

function VerifyRequest() {
  return (
    <div className="h-full w-full bg-background">
      <div> asda</div>
    </div>
  );
}

export default VerifyRequest;
